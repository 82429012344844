import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  
  {
    path: '/admin',
    name: 'indexAdmin',
    component: () => import('../views/admin/IndexView.vue'),
    meta: { requiresAuth: true }, // 加入 meta 標籤來指示需要驗證
    children:[
      {
        path: 'lady',
        name: 'lady',
        component: () => import('../views/admin/LadyView.vue'),
      },
      {
        path: 'service',
        name: 'service',
        component: () => import('../views/admin/ServiceView.vue'),
      },
      {
        path: 'location',
        name: 'location',
        component: () => import('../views/admin/LocationView.vue'),
      }
    ]
  },{
    path: '/',
    name: 'indexUser',
    redirect: '/page',
    component: () => import('../views/user/IndexView.vue'),
    children:[
      {
        path: 'page',
        name: 'page',
        component: () => import('../views/user/pageView.vue'),
      }
    ]
  },
  {
    path: '/admin/login',
    name: 'login',
    component: () => import('../views/admin/LoginView.vue'),
  }
]

const router = new VueRouter({
  mode:'history',
  routes
})

// 添加 beforeEach 守衛來檢查登入狀態
router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  // 檢查路由元信息和登入狀態
  if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
    next({ path: '/admin/login' }); // 指定未登入時跳轉的登入頁面路徑
  } else {
    next(); // 如果不需要登入驗證或者已經登入，則正常導航
  }
});

export default router
